<template>
  <mi-select
    :model-value="modelValue"
    :options="bomValidationResultTypes"
    :menu-offset="[0, 3]"
    class="text-weight-bold"
    menu-anchor="bottom right"
    menu-self="top right"
    option-label="label"
    option-value="key"
    options-selected-class="text-accent"
    popup-content-class="bom-results-types-dropdown"
    borderless
    dense
    @update:model-value="updateActiveValidationResultType"
  >
    <!-- Option slot -->
    <template #option="{ opt, itemProps, selected }">
      <mi-list-item :key="opt.key" v-bind="itemProps">
        <!-- Label -->
        <mi-list-item-section>
          <mi-list-item-label> {{ opt.label }} </mi-list-item-label>
        </mi-list-item-section>

        <!-- Badge -->
        <mi-list-item-section avatar>
          <mi-badge :color="selected ? 'accent' : 'primary'" :label="setResultTypeBadgeValue(opt.key)"></mi-badge>
        </mi-list-item-section>
      </mi-list-item>
    </template>
  </mi-select>
</template>

<script>
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import { BOM_VALIDATION_RESULT_TYPES } from '@/constants'
  import { BOM_DROPDOWN_CHANGED } from '@/utils/analytics/constants'

  export default {
    name: 'BomValidationResultsTypesSelect',
    props: {
      modelValue: {
        type: Object,
        required: true
      },
      resultsTotalSizes: {
        type: Object,
        required: true
      }
    },
    emits: ['update:model-value', 'value:has-changed'],
    data: () => ({
      bomValidationResultTypes: Object.values(BOM_VALIDATION_RESULT_TYPES)
    }),
    created() {
      const { selectedResultType } = this.$route.query || {}
      const validationResultType = this.bomValidationResultTypes.find(
        resultType => resultType.key === selectedResultType
      ) || {}

      if (Object.keys(validationResultType).length) {
        this.emitUpdateModelValue(validationResultType)
      }
    },
    methods: {
      emitUpdateModelValue(modelValue = {}) {
        this.$emit('update:model-value', modelValue)
        this.$emit('value:has-changed')
      },
      setResultTypeBadgeValue(resultTypeKey = '') {
        return this.resultsTotalSizes[resultTypeKey]?.totalSize || 0
      },
      updateActiveValidationResultType(validationResultType = {}) {
        this.emitUpdateModelValue(validationResultType)
        this.$router.replace({
          query: { ...this.$route.query, selectedResultType: validationResultType.key }
        })

        // Analytics
        recordAnalytics(
          BOM_DROPDOWN_CHANGED,
          { type: validationResultType.key }
        )
      }
    }
  }
</script>

<style lang="scss">
  .bom-results-types-dropdown {
    // stylelint-disable declaration-no-important
    min-width: 190px !important;
  }
</style>
