<template>
  <slot v-if="!results.length">
    <validation-results-empty-state></validation-results-empty-state>
  </slot>
  <slot v-else>
    <div class="bom-validation-results">
      <div class="bom-validation-results__content">
        <mi-list
          v-for="result in results"
          :key="result"
          class="bom-validation-results__content--card bg-white q-py-sm"
          bordered
        >
          <template v-if="type === 'ambiguous'">
            <bom-validations-results-item
              :content="getAmbiguousName(result)"
              :emphasis="true"
            >
            </bom-validations-results-item>
          </template>

          <bom-validations-results-item
            :content="result.explanation"
          >
          </bom-validations-results-item>
          <bom-validations-results-item
            :content="getPlanningPeriod(result)"
            title="Planning period"
            :multi-display="true"
          >
          </bom-validations-results-item>
        </mi-list>
      </div>
    </div>
  </slot>
</template>

<script>
  import ValidationResultsEmptyState
    from '@/components/rule-validation/validations/validation-results-empty-state/ValidationResultsEmptyState.vue'
  import BomValidationsResultsItem
    from '@/components/rule-validation/validations/validation-results/bom/BomValidationsResultsItem.vue'

  export default {
    name: 'BomValidationsResults',
    components: {
      ValidationResultsEmptyState,
      BomValidationsResultsItem
    },
    props: {
      results: {
        type: Array,
        required: true,
        default: () => []
      },
      type: {
        type: String,
        required: false,
        default: ''
      }
    },
    methods: {
      getPlanningPeriod(data) {
        if (data && (data.rangeFrom || data.rangeTo)) {
          const rangeFrom = data.rangeFrom || ''
          const rangeTo = data.rangeTo ? ` - ${ data.rangeTo }` : ''
          return `${ rangeFrom }${ rangeTo }`
        }
        return ''
      },
      getAmbiguousName(item) {
        return `${ item.ambiguous1Id2 } ${ item.ambiguous1Name } - ${ item.ambiguous2Id2 } ${ item.ambiguous2Name }`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bom-validation-results {
    gap: .5rem;
    border-left: 4px solid $mi-red-500;
    background-color: $mi-silver-50;

    &__content {
      padding: 1%;

      &--card {
        border-color: $mi-anthracite-100;
        margin-bottom: 1%;
      }
    }
  }
</style>
