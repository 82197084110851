<template>
  <div class="on-demand-validations__validations--header">
    <h5 class="header-title text-uppercase">Bom Validation</h5>
    <h1 class="header-validation-name text-uppercase">Validation Name</h1>
  </div>
</template>

<script>
  export default {
    name: 'OnDemandHeader'
  }
</script>
