<template>
  <div :class="classProp" class="on-demand-validation-results-tabs-header">
    <mi-tabs
      :model-value="modelValue"
      class="on-demand-validation-results-tabs"
      dense
      @update:model-value="updateSelectedTab"
    >
      <mi-tab
        :key="validationErrorSettings.key"
        :name="validationErrorSettings.key"
        :label="validationErrorSettings.label"
        :badge="getElementsLength(validationErrorSettings.key)"
      ></mi-tab>
      <mi-tab
        :key="invalidErrorSettings.key"
        :name="invalidErrorSettings.key"
        :label="invalidErrorSettings.label"
        :badge="getElementsLength(invalidErrorSettings.key)"
      ></mi-tab>
    </mi-tabs>
  </div>
</template>

<script>
  import { BOM_VALIDATION_ERROR_TYPES } from '@/constants'
  import { createNamespacedHelpers } from 'vuex'

  const { mapState } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'ValidationResultsTabs',
    props: {
      modelValue: {
        type: String,
        required: true
      },
      classProp: {
        type: String,
        required: false,
        default: 'flex justify-center'
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      validationErrorSettings: BOM_VALIDATION_ERROR_TYPES.AMBIGUOUS,
      invalidErrorSettings: BOM_VALIDATION_ERROR_TYPES.INCOMPLETE
    }),
    computed: {
      ...mapState(['validation'])
    },
    mounted() {
      const { selectedErrorType } = this.$route.query || {}
      const isRouteSelectedErrorTypeValid = Object.values(BOM_VALIDATION_ERROR_TYPES).some(
        errorType => errorType.key === selectedErrorType
      )

      if (isRouteSelectedErrorTypeValid) {
        this.emitUpdateModelValue(selectedErrorType)
      }
      else {
        this.emitUpdateModelValue(BOM_VALIDATION_ERROR_TYPES.AMBIGUOUS.key)
      }
    },
    methods: {
      emitUpdateModelValue(modelValue = '') {
        this.$emit('update:model-value', modelValue)
      },
      updateSelectedTab(selectedErrorType = '') {
        this.emitUpdateModelValue(selectedErrorType)
        this.$router.replace({
          query: { ...this.$route.query, selectedErrorType }
        })
      },
      getElementsLength(key) {
        const selectedValidationErrors = this.validation.totalErrorTypes[this.validation.selectedValidationType]
        return (selectedValidationErrors[key] || []).length
      }
    }
  }
</script>

<style lang="scss">
  .on-demand-validation-results-tabs {
    .q-tab__label {
      font-size: .875rem;
    }
  }
</style>
