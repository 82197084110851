import { VALIDATIONS_OPTIONS } from '@/constants'
import { addPrefixToComplexPlanningPeriod } from '@/utils/planningPeriod'
import formatDate from '@/utils/formatDate'

function mapBOM(validation, userExport) {
  return [
    {
      validationId: { key: 'validation id', value: validation.id },
      validationCreator: { key: 'validation creator', value: validation.user },
      exportName: { key: 'export name', value: userExport.name },
      exportCreator: { key: 'export creator', value: userExport.onDemandExportMetadata.creator },
      exportCreationDate: { key: 'export date', value: formatDate(userExport.creationDate) }
    },
    {
      productModel: { key: 'product model', value: validation.businessName },
      optionSet: { key: 'option set', value: userExport.onDemandExportMetadata.optionSet },
      planningPeriod: {
        key: 'planning period',
        value: addPrefixToComplexPlanningPeriod(
          validation.planningPeriodRange.from, validation.planningPeriodRange.to
        )
      }
    },
    {
      validationCreationDate: {
        key: 'validation created',
        value: formatDate(validation.startDate)
      },
      validationFinishDate: {
        key: 'validation finished',
        value: formatDate(validation.endDateTime)
      }
      // amountOfNoTtriggerableMmas: { key: 'amount of not triggerable mmas', value: '' },
      // kvValidity: { key: 'kv validity', value: '' }
    },
    {
      // combinationRules: { key: 'combination rules', value: '' },
      tarStatus: { key: 'tar status', value: userExport.onDemandExportMetadata.tarStateConfig },
      status: { key: 'validation status', value: validation.status }
    }
  ]
}

export function mapSummaryData(validation, userExport, validationType) {
  const summaryData = {
    [VALIDATIONS_OPTIONS.BOM]: mapBOM(validation, userExport)
  }

  return summaryData[validationType]
}
