/**
 * Adds 'PP' prefix to planning period if it was missing.
 * @param value (e.g. 202102)
 * @returns {string|string} (e.g. PP202102)
 */
export const addPrefixToPlanningPeriod = (value = '') => (
  !value || value.toUpperCase().startsWith('PP') ? value : `PP${ value }`
)

/**
 * Remove 'PP' prefix from planning period.
 * @param value (e.g. PP202102)
 * @returns {string} (e.g. 202102)
 */
export const removePrefixFromPlanningPeriod = (value = '') => (
  value ? value.toUpperCase().replace('PP', '') : value
)

/**
 * Remove ' - ' mask from planning period.
 * @param value (e.g. 202102 - 202103)
 * @returns {initialPP: string, finalPP: string}
 */
export const removePlanningPeriodMask = (value = '') => {
  const [initialPP, finalPP] = value.split(' - ')
  return { initialPP, finalPP }
}

/**
 * Adds 'PP' prefix to planning period if it was missing.
 * @param fromDate
 * @param toDate
 * @returns {`PP ${string} - ${string}`}
 */
export function addPrefixToComplexPlanningPeriod(fromDate, toDate) {
  if (fromDate && toDate) {
    return `PP ${ removePrefixFromPlanningPeriod(fromDate) } - ${ removePrefixFromPlanningPeriod(toDate) }`
  }

  if (!toDate && fromDate) {
    return `PP ${ removePrefixFromPlanningPeriod(fromDate) }`
  }
  return ''
}
