<template>
  <div class="on-demand-summary-validations">
    <div class="on-demand-summary-validations__header">
      <h3 class="summary-title">Summary</h3>
      <img
        class="summary-collapse__img"
        src="@/assets/images/rule-validation/summary_arrow.svg"
        alt="summary-arrow-img"
      />
    </div>
    <div class="on-demand-summary-validations__details">
      <on-demand-summary-details-validations></on-demand-summary-details-validations>
    </div>
  </div>
</template>

<script>
  import OnDemandSummaryDetailsValidations from '@/components/rule-validation/validations/summary/SummaryDetails.vue'

  export default {
    name: 'OnDemandSummaryValidations',
    components: { OnDemandSummaryDetailsValidations }
  }
</script>

<style lang="scss">
  .on-demand-summary-validations {
    border-left: .15rem solid $mi-anthracite-50;
    width: 100%;
    height: 100%;

    &__header {
      padding: 3% 5%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      border-bottom: .15rem solid $mi-anthracite-50;

      .summary-title,
      .summary-collapse {
        margin: 0;
      }

      .summary-collapse__img {
        width: 18px;
      }
    }
  }
</style>
