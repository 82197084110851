<template>
  <template v-if="!isLoadingValidationResults">
    <div class="on-demand-validations__return-btn-wrapper">
      <div class="on-demand-validations__btn " @click="handleAppFeatureClick()">
        <img
          class="on-demand-validations__back-btn"
          src="@/assets/images/arrow-left.svg"
          alt="Return to OnDemand Exports List"
        />
        <button
          class="on-demand-validations__return-btn"
        >
          return to validations
        </button>
      </div>
    </div>
    <div class="on-demand-validations__wrapper column full-height">
      <div class="on-demand-validations__validations">
        <on-demand-header></on-demand-header>
        <div class="on-demand-validations__validations--content">
          <div class="row row-border">
            <div class="col-2 custom-col self-start">
              <bom-validation-results-types-select
                v-model="activeValidationResultType"
                :results-total-sizes="validation.totalErrorTypes"
              >
              </bom-validation-results-types-select>
            </div>
            <div class="col-8 self-center">
              <validation-results-tabs v-model="selectedTab"></validation-results-tabs>
            </div>
            <div class="col-2 custom-col self-center">
            </div>
          </div>
          <!-- Validation results by error type -->
          <mi-tab-panels v-model="selectedTab" class="full-height" keep-alive>
            <mi-tab-panel :name="BOM_VALIDATION_ERROR_TYPES.AMBIGUOUS.key" class="q-px-none">
              <bom-validations-results
                :results="getAmbiguousData"
                type="ambiguous"
              ></bom-validations-results>
            </mi-tab-panel>
            <mi-tab-panel :name="BOM_VALIDATION_ERROR_TYPES.INCOMPLETE.key" class="q-px-none">
              <bom-validations-results
                :results="getIncompleteData"
              ></bom-validations-results>
            </mi-tab-panel>
          </mi-tab-panels>
        </div>
      </div>
      <div class="on-demand-validations__summary">
        <on-demand-summary-validations></on-demand-summary-validations>
      </div>
    </div>
  </template>
  <mi-inner-loading class="bg-white" :showing="isLoadingValidationResults">
    <mi-spinner size="70px"></mi-spinner>
    <h6 class="q-mt-lg q-mb-none"> Loading validation. Please wait ... </h6>
  </mi-inner-loading>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import OnDemandSummaryValidations from '@/components/rule-validation/validations/summary/Summary.vue'
  import OnDemandHeader from '@/components/rule-validation/validations/header/OnDemandHeader.vue'
  import ValidationResultsTabs from
    '@/components/rule-validation/validations/validation-results-tabs/ValidationResultsTabs.vue'
  import BomValidationsResults from
    '@/components/rule-validation/validations/validation-results/bom/BomValidationsResults.vue'
  import BomValidationResultsTypesSelect from
    '@/components/validations/bom/validation-results/BomValidationResultsTypesSelect.vue'
  import { BOM_VALIDATION_ERROR_TYPES, BOM_VALIDATION_RESULT_TYPES, VALIDATIONS_OPTIONS } from '@/constants'
  import { ON_DEMAND_EXPORTS_ROUTE } from '@/router/routeNames'
  import { getUserExportMetadata, getBOMValidation } from '@/api'
  import { UPDATE_SELECTED_VALIDATION_TYPE } from '@/store/modules/rule-validation/mutationTypes'
  import { mapSummaryData } from '@/components/rule-validation/utils/mappers/summaryMappers'

  const { mapActions, mapState, mapGetters, mapMutations } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'OnDemandValidations',
    components: {
      OnDemandHeader,
      OnDemandSummaryValidations,
      ValidationResultsTabs,
      BomValidationsResults,
      BomValidationResultsTypesSelect
    },
    data: () => ({
      selectedTab: '',
      BOM_VALIDATION_ERROR_TYPES,
      activeValidationResultType: BOM_VALIDATION_RESULT_TYPES.COMPONENT_VARIANT,
      activeValidationResultTypeWatch: '',
      isLoadingValidationResults: false
    }),
    computed: {
      ...mapState(['validation']),
      ...mapGetters(['getAmbiguousData', 'getIncompleteData'])
    },
    async created() {
      this.getValidationResultsData()

      this.currentDisplayedElements(this.activeValidationResultType.key)

      this.$watch('activeValidationResultType', validationResultType => {
        if (validationResultType?.key) this.currentDisplayedElements(validationResultType.key)
      })
    },
    methods: {
      ...mapActions(['setValidationResultsData']),
      ...mapMutations({ UPDATE_SELECTED_VALIDATION_TYPE }),

      async getValidationResultsData() {
        const { id: validationId, userExportID } = this.$route.params || {}
        if (validationId && userExportID) {
          try {
            this.isLoadingValidationResults = true
            const fetchValidationAndUserExportMetadata = Promise.all([
              getBOMValidation(validationId),
              getUserExportMetadata(userExportID)
            ])

            const [validation, userExportMetadata] = await fetchValidationAndUserExportMetadata
            const summaryData = mapSummaryData(
              validation, userExportMetadata, VALIDATIONS_OPTIONS.BOM
            )
            this.setValidationResultsData({ validation, summaryData })
          }
          catch (err) {
            console.error('err', err)
          }
          finally {
            this.isLoadingValidationResults = false
          }
        }
      },
      currentDisplayedElements(key) {
        this.UPDATE_SELECTED_VALIDATION_TYPE({ type: key })
      },
      handleAppFeatureClick() {
        this.$router.push(ON_DEMAND_EXPORTS_ROUTE)
      }
    }
  }
</script>

<style lang="scss">

  ::-webkit-scrollbar {
    display: none;
  }

  .self-center {
    margin-left: 4%;
  }

  .row-border {
    border-bottom: 1px solid $mi-anthracite-50;
  }

  .row > .custom-col {
    width: 140px;
  }

  .on-demand-validations {
    &__btn {
      display: flex;
      align-items: center;
      gap: 11px;
      cursor: pointer;
    }

    &__wrapper {
      display: flex;
      height: 100%;
      flex-direction: row;
      padding-left: 2rem;
    }

    &__return-btn-wrapper {
      padding-left: 32px;
      height: 48px;
      width: 100%;
      background-color: #eaedf3;
      display: flex;
      align-items: center;
    }

    &__return-btn {
      color: $mi-anthracite-800;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      // remove all btn styles
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;
    }

    &__back-btn {
      width: 18px;
      height: 14px;
    }

    ::v-deep(&__return-btn:hover) {
      color: #000000;
    }

    &__validations {
      width: 75%;
      display: flex;
      flex-direction: column;
      max-height: 100%;

      &--header {
        .header-validation-name {
          margin-top: 0;
          font-size: 36px;
        }

        .header-title {
          margin-bottom: 0;
          color: $mi-red-500;
          font-size: 18px;
        }
      }

      &--content {
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        height: 70%;
        margin-right: 2%;
      }
    }

    &__summary {
      width: 25%;
    }
  }
</style>
