<template>
  <div class="flex column justify-center items-center full-height">
    <img
      src="@/assets/images/rule-validation/results_not_found_icon.svg"
      alt="Not found icon"
    />
    <span class="text-subtitle2 text-primary text-weight-bold q-mb-none q-mt-md">
      {{ message }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'ValidationResultsEmptyState',
    props: {
      message: {
        type: String,
        required: false,
        default: 'There are no ambiguous results'
      }
    }
  }
</script>

<style>
  span {
    font-family: $mi-typography-font-family-condensed;
  }
</style>
